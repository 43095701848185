import React from "react";
import Button from "./Button";

export default function CTA() {
  return (
    <>
      <div className="ptb-7 text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3>
                Discover how{" "}
                <span className="primary-text">Yawm Tech Solutions</span> can
                drive your business forward
              </h3>
              <Button url="/contact" title="Contact Today!" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
