import React from "react";
import CardComp from "../Components/CardComp";
import SImg1 from "../Assets/img/home/01.png";
import SImg2 from "../Assets/img/home/02.png";
import SImg3 from "../Assets/img/home/03.png";
import SImg4 from "../Assets/img/home/04.png";
import CTA from "../Components/CTA";

export default function Services() {
  const Services = [
    {
      img: SImg1,
      head: "Application Development",
      body: "Crafting robust, scalable, and user-friendly applications tailored to your business needs. Our team of experienced developers leverages the latest technologies to deliver high-quality solutions that drive efficiency and growth.",
    },
    {
      img: SImg2,
      head: "Automation Testing",
      body: "Ensuring your software’s reliability and performance with our expert automation testing services. Our comprehensive testing strategies are designed to identify and eliminate bugs before they impact your users.",
    },
    {
      img: SImg3,
      head: "Mobile App Development",
      body: "Creating seamless and engaging mobile experiences for iOS and Android platforms.Creating seamless and engaging mobile experiences for iOS and Android platforms. Our skilled developers craft intuitive and visually appealing apps that captivate users and drive engagement. ",
    },
    {
      img: SImg4,
      head: "Software Solutions",
      body: "Providing customized software solutions to streamline your business processes and enhance efficiency. Our tailored approach ensures that each solution is perfectly aligned with your specific needs and objectives.",
    },
  ];
  return (
    <>
      <div className="container pt-5 text-center">
        <div className="row">
          <div className="col-md-12 pb-5">
            <h3>
              Our <span className="primary-text">Services</span>
            </h3>
          </div>

          <div className="row">
            {Services.map((item) => (
              <>
                <div className="col-md-6 pb-5">
                  <CardComp img={item.img} head={item.head} body={item.body} />
                </div>
              </>
            ))}
          </div>
        </div>
        <hr />
      </div>
      <CTA />
    </>
  );
}
