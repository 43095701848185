import React from "react";
import CTA from "../Components/CTA";
import { Text } from "@nikitamallya/react-ui-components";
import AbtImg from "../Assets/img/home/abt.png";

export default function About() {
  return (
    <>
      <div className="ptb-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-4 text-right mobcenter">
            <Text content="Established in 2023" variant="h3" weight="bold" />
            <Text content="At Yawn Tech Solutions, we transform ideas into reality through innovative technology solutions. Established in 2023, we specialize in delivering top-notch application development, automation testing, mobile app development, and comprehensive software solutions. Our mission is to empower businesses with cutting-edge technology, ensuring success and growth in the digital age." />
          </div>
          <div className="col-md-4">
            <img src={AbtImg} alt="" className="img-fluid floating-item" />
          </div>
        </div>
      </div>
      <div className="container">
        <hr />
        <CTA />
      </div>
    </>
  );
}
