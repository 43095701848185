import React from "react";
import { Text } from "@nikitamallya/react-ui-components";
import CardComp2 from "./CardComp2";
import icon1 from "../Assets/img/home/icon1.svg";
import icon2 from "../Assets/img/home/icon2.svg";
import icon3 from "../Assets/img/home/icon3.svg";
import icon4 from "../Assets/img/home/icon4.svg";

export default function WhyChooseUs() {
  const WhyChooseUs = [
    {
      icon: icon1,
      head: "Innovative Solutions",
      body: "We stay ahead of the curve with the latest technological advancements.",
    },
    {
      icon: icon2,
      head: "Expert Team",
      body: "Our team comprises experienced professionals dedicated to delivering excellence.",
    },
    {
      icon: icon3,
      head: "Customer-Centric Approach",
      body: "We prioritize our clients' needs and work closely with them to achieve their goals.",
    },
    {
      icon: icon4,
      head: "Quality Assurance",
      body: "Our commitment to quality ensures that we deliver nothing but the best.",
    },
  ];
  return (
    <>
      <div className="whychooseus">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pb-4">
              <h3 className="text-white">
                Why <span className="primary-text">Choose Us?</span>
              </h3>
            </div>
          </div>
          <div className="row pb-5">
            {WhyChooseUs.map((item) => (
              <>
                <div className="col-md-6 pb-5">
                  <CardComp2
                    icon={item.icon}
                    head={item.head}
                    body={item.body}
                  />
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
