import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/img/logo.svg";
import Fb from "../Assets/img/home/fb.svg";
import Insta from "../Assets/img/home/insta.svg";
import LinkedIn from "../Assets/img/home/linkedin.svg";
import X from "../Assets/img/home/x.svg";

export default function Footer() {
  return (
    <>
      <div className="footer ptb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 mobcenter mobpb">
              <div>
                © 2024 <Link to="/">Yawm Tech Solutions</Link>
              </div>
              <div>
                <small>
                  Designed by <a href="https://www.sociozite.com">Sociozite</a>
                </small>
              </div>
            </div>
            <div className="col-md-4 text-center mobpb">
              <Link to="/">
                {" "}
                <img src={Logo} alt="" className="img-fluid" />
              </Link>
            </div>
            <div className="col-md-4 social-icons text-right mobcenter">
              <Link to="">
                <img src={Fb} alt="" className="img-fluid" />
              </Link>
              <Link to="">
                <img src={Insta} alt="" className="img-fluid" />
              </Link>
              <Link to="">
                <img src={LinkedIn} alt="" className="img-fluid" />
              </Link>
              <Link to="">
                <img src={X} alt="" className="img-fluid" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
