import React from "react";
import { Text } from "@nikitamallya/react-ui-components";

export default function CardComp2(props) {
  return (
    <>
      <div className="d-flex gap-3 align-items-center">
        <div className="icon">
          <img src={props.icon} alt="" className="floating-item" />
        </div>
        <div className="item">
          <Text content={props.head} variant="h5" weight="bold" color="white" />
          <Text content={props.body} color="white" />
        </div>
      </div>
    </>
  );
}
