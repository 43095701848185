import React from "react";
import Banner from "../Components/Banner";
import HomeServices from "../Components/HomeServices";
import WhyChooseUs from "../Components/WhyChooseUs";
import CTA from "../Components/CTA";

export default function Home() {
  return (
    <>
      <Banner />
      <HomeServices />
      <WhyChooseUs />
      <CTA />
    </>
  );
}
