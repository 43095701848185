import React from "react";
import ContactImg from "../Assets/img/contact.png";
import { Link } from "react-router-dom";
import Phone from "../Assets/img/phone.svg";
import Email from "../Assets/img/envelope.svg";
import Maps from "../Assets/img/maps.svg";

export default function Contact() {
  return (
    <>
      <div className="ptb-5">
        <div className="container">
          <h3 className="mobcenter">Get in Touch</h3>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={ContactImg} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <p>
                We would love to hear from you! Whether you have a question
                about our services, need assistance, or want to discuss a new
                project, our team is here to help. Reach out to us through any
                of the methods below, and we will get back to you as soon as
                possible.
              </p>
              <div className="pb-2">
                <Link to="tel:9535659813">
                  <img src={Phone} alt="" className="img-fluid" /> +91
                  9535659813
                </Link>
              </div>
              <div className="pb-2">
                <Link to="mailto:Ajaruddin.kachavi@yawmtech.com">
                  <img src={Email} alt="" className="img-fluid" />{" "}
                  Ajaruddin.kachavi@yawmtech.com
                </Link>
              </div>
              <div>
                <Link to="">
                  <img src={Maps} alt="" className="img-fluid" /> #1236, A C
                  Kachavi, Basveswara Nagar 6th Cross Hirekerur, Karnataka.
                  PinCode :581111
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
