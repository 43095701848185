import React from "react";
import { Link } from "react-router-dom";

export default function Button(props) {
  return (
    <>
      <div className="floating-item">
        <Link to={props.url} className="btn-primary ">
          {props.title}
        </Link>
      </div>
    </>
  );
}
