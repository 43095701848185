import React from "react";
import BannerImg from "../Assets/img/home/banner.png";
import AbtImg from "../Assets/img/home/abt.png";
import { Text } from "@nikitamallya/react-ui-components";

export default function Banner() {
  return (
    <>
      <div className="banner">
        <div className="container">
          <div className="row align-items-center banner1">
            <div className="col-md-6">
              <img src={BannerImg} alt="" className="img-fluid floating-item" />
            </div>
            <div className="col-md-6 mobcenter">
              <Text content="Welcome to" variant="h5" />
              <Text
                content="Yawm Tech Solutions"
                variant="h1"
                customColor="#11998E"
                weight="bold"
              />
              <Text content="At Yawn Tech Solutions, we transform ideas into reality through innovative technology solutions. We prioritize our clients' needs and work closely with them to achieve their goals." />
            </div>
          </div>
          <div className="p-5 mobnone"></div>
          <div className="p-4 "></div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-4 text-right mobcenter">
              <Text
                content="Established in 2023"
                variant="h3"
                color="white"
                weight="bold"
              />
              <Text
                content="At Yawn Tech Solutions, we transform ideas into reality through innovative technology solutions. Established in 2023, we specialize in delivering top-notch application development, automation testing, mobile app development, and comprehensive software solutions. Our mission is to empower businesses with cutting-edge technology, ensuring success and growth in the digital age."
                color="white"
              />
            </div>
            <div className="col-md-4">
              <img src={AbtImg} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="p-4"></div>
        </div>
      </div>
    </>
  );
}
