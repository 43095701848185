import React from 'react'
import {Text} from '@nikitamallya/react-ui-components'

export default function CardComp(props) {
  return (
    <>
      <img src={props.img} alt="" className="w-50 pb-3 floating-item" />
      <Text content={props.head} variant="h5" weight="bold" />
      <Text content={props.body} className=" mx-auto" />
    </>
  );
}
